NavItemComponent
<template>
  <div>
    <side-bar class="p-2" :sidebar-item-color="sidebarBackground">
      <mobile-menu slot="content"></mobile-menu>
      <!-- <sidebar-link to="/dashboard">
        <md-icon>dashboard</md-icon>
        <p>Dashboard</p>
      </sidebar-link> -->

      <div v-if="menu">
        <NavItemComponent
          v-for="(menu_item, index) in menu"
          :key="index"
          :title="menu_item.title"
          :route_name="menu_item.route_name"
          :icon="menu_item.icon"
          :child="menu_item.child"
          :is_visible="menu_item.is_visible"
          :has_children="
            menu_item.child !== undefined && menu_item.child.length > 0
          "
        >
        </NavItemComponent>
      </div>

      <div style="height: 100px"></div>
    </side-bar>
  </div>
</template>

<script>
import MobileMenu from "./MobileMenu.vue";
import NavItemComponent from "./NavItem.vue";

export default {
  components: {
    MobileMenu,
    NavItemComponent,
  },
  data() {
    return {
      sidebarBackground: "red",
      // sidebarBackgroundImage: require("@/assets/img/sidebar-2.jpg"),

      menu: [
        {
          title: "Dashboard",
          icon: "dashboard",
          route_name: "/dashboard",
          is_visible:
            this.$store.getters["auth/has_module_permission"]("view_dashboard"),
        },
        {
          route_name: "#professional-management",
          title: "Professionals",
          icon: "compass_calibration",
          is_visible:
            this.$store.getters["auth/has_module_permission"](
              "view_professional"
            ),
          child: [
            {
              route_name: "Professional Management",
              title: "Professional Management",
              alias: "PM",
            },
            {
              route_name: "Professional Services",
              title: "Professional Services",
              alias: "PS",
            },
          ],
        },
        {
          route_name: "#handyman-management",
          title: "Handymen",
          icon: "compass_calibration",
          is_visible:
            this.$store.getters["auth/has_module_permission"]("view_handyman"),
          child: [
            {
              route_name: "Handyman Management",
              title: "Handyman Management",
              alias: "HM",
            },
            {
              route_name: "Handyman Services",
              title: "Handyman Services",
              alias: "HS",
            },
          ],
        },

        {
          route_name: "#vendor-management",
          title: "Vendor",
          icon: "compass_calibration",
          is_visible:
            this.$store.getters["auth/has_module_permission"]("view_vendor"),
          child: [
            {
              route_name: "Vendor Management",
              title: "Vendor Management",
              alias: "VM",
            },
          ],
        },
        {
          title: "Portfolios",
          route_name: "/portfolios",
          icon: "work_outline",
          is_visible:
            this.$store.getters["auth/has_module_permission"]("view_portfolio"),
        },
        {
          route_name: "#estate-management",
          title: "Real Estates",
          icon: "business",
          child: [
            {
              route_name: "Estate Management",
              title: "Estate Management",
              alias: "EM",
              is_visible:
                this.$store.getters["auth/has_module_permission"](
                  "view_estate"
                ),
            },
            {
              route_name: "Estate Realtors",
              title: "Realtors Management",
              alias: "RM",
            },
            {
              route_name: "Estate Features",
              title: "Feature Categories",
              alias: "FC",
            },
            {
              route_name: "Estate Types",
              title: "Property Types",
              alias: "PT",
            },
          ],
        },
        {
          route_name: "#catalog-management",
          title: "Products",
          icon: "store",
          child: [
            {
              route_name: "products",
              title: "All Products",
              alias: "PD",
              is_visible:
                this.$store.getters["auth/has_module_permission"](
                  "view_product"
                ),
            },
            {
              route_name: "Product Tags",
              title: "Tags",
              alias: "TG",
            },
            {
              route_name: "Product Categories",
              title: "Categories",
              alias: "CA",
            },
            {
              route_name: "Product Attribute",
              title: "Attributes",
              alias: "AT",
            },
          ],
        },
        {
          route_name: "#order-management",
          title: "Orders",
          icon: "shopping_basket",
          is_visible:
            this.$store.getters["auth/has_module_permission"]("view_product"),
          child: [
            {
              route_name: "product-orders",
              title: "All Orders",
              alias: "OR",
              is_visible:
                this.$store.getters["auth/has_module_permission"]("view_order"),
            },
            {
              route_name: "transactions",
              title: "Transactions",
              alias: "TL",
              is_visible:
                this.$store.getters["auth/has_module_permission"](
                  "view_transaction"
                ),
            },
            {
              route_name: "Buyers",
              title: "Buyers",
              alias: "BY",
              is_visible:
                this.$store.getters["auth/has_module_permission"]("view_buyer"),
            },
            {
              route_name: "Order Payout",
              title: "Order Payouts",
              alias: "OP",
              is_visible:
                this.$store.getters["auth/has_module_permission"]("view_order"),
            },
          ],
        },
        {
          route_name: "#user-management",
          title: "Users",
          icon: "group",
          is_visible:
            this.$store.getters["auth/has_module_permission"]("view_user"),
          child: [
            {
              route_name: "User Management",
              title: "User Management",
              alias: "UM",
            },
            {
              route_name: "Role Management",
              title: "Role Management",
              alias: "RM",
              is_visible:
                this.$store.getters["auth/has_module_permission"]("view_role"),
            },
            {
              route_name: "User Login Location",
              title: "Users Location",
              alias: "UL",
            },
          ],
        },
        {
          route_name: "#buyer-management",
          title: "Buyers",
          icon: "group",
          is_visible:
            this.$store.getters["auth/has_module_permission"]("view_role"),
          child: [
            {
              route_name: "Buyers Management",
              title: "Buyers Management",
              alias: "BM",
            },
          ],
        },
        {
          route_name: "#affiliate-management",
          title: "Affiliate",
          icon: "group",
          is_visible:
            this.$store.getters["auth/has_module_permission"]("view_affiliate"),
          child: [
            {
              route_name: "Affiliate Management",
              title: "Affiliate Management",
              alias: "AM",
            },
            {
              route_name: "Affiliate Sales",
              title: "Sales Management",
              alias: "SM",
              is_visible: this.$store.getters["auth/has_module_permission"](
                "view_affiliate_role"
              ),
            },
            {
              route_name: "Affiliate Payouts",
              title: "Affiliate Payouts",
              alias: "AP",
            },
          ],
        },
        {
          route_name: "#point-management",
          title: "Points",
          icon: "money",
          is_visible:
            this.$store.getters["auth/has_module_permission"]("view_points"),
          child: [
            {
              route_name: "Points",
              title: "Point  Management",
              alias: "PM",
            },
            {
              route_name: "Point Orders",
              title: "Point Order Management",
              alias: "POM",
            },
          ],
        },
        {
          route_name: "Faq",
          title: "FAQ",
          icon: "book",
          is_visible:
            this.$store.getters["auth/has_module_permission"]("view_faq"),
        },
        {
          route_name: "promotions",
          title: "Promotions",
          icon: "compass_calibration",
          is_visible:
            this.$store.getters["auth/has_module_permission"]("promotions"),
        },
        {
          route_name: "#site-management",
          title: "Site Management",
          icon: "settings",
          child: [
            {
              route_name: "Adverts",
              title: "Adverts",
              alias: "ADS",
              is_visible:
                this.$store.getters["auth/has_module_permission"](
                  "view_advertisement"
                ),
            },
            {
              route_name: "Commission",
              title: "Commission",
              alias: "%%",
              is_visible:
                this.$store.getters["auth/has_module_permission"](
                  "view_commission"
                ),
            },
            {
              route_name: "Testimonial",
              title: "Testimonial",
              alias: "TS",
              is_visible:
                this.$store.getters["auth/has_module_permission"]("*"),
            },

            {
              route_name: "Pages Content",
              title: "Pages Content",
              alias: "PC",
              is_visible:
                this.$store.getters["auth/has_module_permission"]("*"),
            },
          ],
        },
      ],
    };
  },
};
</script>
